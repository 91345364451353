@import 'fonts';
@import 'vars';
@import 'mixins/styles';
@import 'mixins/calc';

.study {
  &__title {
    @include title;

    &--white {
      color: #fff; } }

  &__text {
    @include text-basic;

    &--white {
      color: #fff; } }

  &-service {
    &__wrap {
      margin-top: 77px;
      margin-bottom: 212px; }

    &__center-pic-wrap {
      height: 100%;
      vertical-align: center;

      img {
        width: 100%;
        height: auto;
        position: relative;
        z-index: 20;
        left: 0; } }

    &__phone-frame {
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 47px 38px 20px;
      @media(min-width: 1200px) {
        padding: 57px 43px 20px; }

      #phone-slider {
        position: relative; } }

    &__col {
      &--left {
        text-align: right;
        @media(max-width: 768px) {
          text-align: left; } }

      &--right {
        text-align: left; } } }

  &-item {
    margin: 0 -15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 20px;
    cursor: pointer;

    &:hover {
      background-color: $highlight; }

    &--highlight {
      background-color: $active-color; }

    &__title {
      @include ff-roboto-light;
      font-size: 1.5em; }

    &__text {
      @include ff-arial;
      font-size: 1em;
      line-height: 1.5em; }

    &-active {
      background-color: $highlight; } }

  &-item-process {
    margin: 0 -15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 20px;

    &--highlight {
      background-color: $active-color; }

    &__title {
      @include ff-roboto-light;
      font-size: 1.5em; }

    &__text {
      @include ff-arial;
      font-size: 1em;
      line-height: 1.5em; } }

  &-access {
    width: 100%;
    margin-bottom: 140px;
    padding-top: 110px;
    padding-bottom: 142px;
    background-image: url('/img/foto_demo.jpg');
    background-position: center center;
    background-size: cover;

    .study__title {
      margin-bottom: 20px; }

    &__col-wrap {
      margin-top: 120px; }

    &__form {
      margin-top: 67px; }

    &__form-input {
      width: 100%;
      padding: 6px 20px;
      margin-bottom: 15px;
      text-align: center; }

    &__button-submit {
      margin-top: 37px;
      padding-left: 50px;
      padding-right: 50px; } }

  &-process {
    margin-bottom: 200px;
    width: 100%;

    &__col-wrap {
      width: 100%;
      background-image: url('/img/study3.png');
      background-position: 45vw 0;
      background-size: contain;
      background-repeat: no-repeat;
      @media(max-width: 991.98px) {
        background-image: none; } }

    .study__title {
      margin-bottom: 20px; } }

  &-details {
    margin-bottom: 160px;
    padding-top: 100px;
    padding-bottom: 120px;
    width: 100%;
    background-image: url('/img/foto_statia.jpg');
    background-position: center center;
    background-size: cover;

    .study__title {
      text-align: left;
      text-transform: uppercase;
      color: #fff;
      font-size: 3em;
      line-height: 1.1em;
      @media(max-width: 480px) {
        font-size: 2.25em; } }

    .study__text {
      margin-bottom: 60px;
      color: #fff;
      text-align: left;
      font-size: 1.5em;
      line-height: 1.5em; }

    .btn {
      padding-left: 100px;
      padding-right: 100px; } }

  &-cashback {
    &__group {
      margin-top: 75px; }

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      margin-bottom: 50px; }

    &__item-num {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 64px;
      height: 64px;
      margin-bottom: 18px;
      border-radius: 32px;
      background-color: $accent;
      font-size: 2.25em;
      font-weight: 600;
      color: #fff; }

    &__item-text {
      text-align: center;
      font-size: 1em;
      line-height: 1.2em;
      color: $headgray; }

    &__item-ellipse {
      position: absolute;
      width: 125px;
      height: 40px;
      top: 32px;
      right: calc(-50% + 57px);
      background-image: url(/img/ellipse.svg);

      &--rotated {
        top: 0;
        background-image: url(/img/ellipse_rotated.svg); } }

    &__subtitle {
      margin-bottom: 50px;
      @include ff-roboto-light;
      font-size: 3em;
      line-height: 1.4em;
      color: $headgray;
      @media(max-width: 480px) {
        font-size: 2.25em; }

      & span {
        display: block;
        @include ff-roboto-bold;
        color: $accent;

        &:after {
          @include rubble();
          font-size: 0.75em; } } }

    &__details-wrap {
      margin-top: 200px; }

    &__details-group {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      flex-wrap: wrap; }

    &__detail {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      min-width: 33%;
      padding-right: 20px;
      margin-bottom: 40px;
      margin-left: auto;
      margin-right: auto;
      min-height: 120px;

      &-sum {
        font-size: 3em;
        font-weight: 600;
        color: $gray;

        span {
          display: inline-flex;
          align-items: baseline;
          font-family: serif;
          font-size: 1.75em;
          line-height: 16px; }

        &::before {
          content: 'Кэшбек';
          display: block;
          font-size: 14px;
          color: $active-color; }

        &::after {
          @include rubble();
          font-size: 24px; } }

      &-text {
        color: $headgray; }

      &-img {
        height: 160px;
        width: 100%;
        transform: translateY(-30px);

        img {
          height: 100%;
          max-height: 160px;
          width: auto; } } } }

  &-offer {
    margin-top: 150px;
    margin-bottom: 150px;

    &__wrap {
      margin-top: 75px; }

    &__text {
      margin-bottom: 10px;
      font-size: 1em;
      color: $headgray; }

    &__button {
      margin-left: 20px;
      margin-bottom: 30px; }
    //
    // стилизация input загрузки файла
    // по умолчанию нельзя менять внешний вид — приходится шаманство разводить с подменой элементов
    &__form {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start; }

    &__form-wrap {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      position: relative;
      width: 100%;
      max-width: 480px;
      min-width: 240px;
      height: 54px;
      margin-right: 30px;
      padding-left: 65px;
      margin-bottom: 20px;
      border-radius: 100px;
      border: 1px solid #bcbcbc; }

    &__input-ico {
      position: absolute;
      top: 12px;
      left: 22px;
      color: $accent; }

    &__input-info {
      display: inline-block;
      flex-grow: 7;
      padding-right: 10px;
      font-size: 1em;
      font-weight: 400;
      color: #bcbcbc;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }

    &__input-file {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1; }

    &__input-file-btn {
      display: inline-block;
      flex-grow: 1;
      margin: 0;
      padding: 12px 32px;
      border-radius: 100px;
      background-color: #f6f6f6;
      text-align: center;
      font-size: 1em;
      font-weight: 400;
      color: #bcbcbc;
      border: 1px solid #bcbcbc;
      cursor: pointer;

      &:hover {
        background-color: $light-blue;
        color: #fff; }

      &:focus {
        outline: 1px dotted #000;
        outline: -webkit-focus-ring-color auto 5px; } }

    &__form-button {
      // padding: 12px 32px
      background-color: $orange;
      font-size: 1em;
      color: #000; } } }
