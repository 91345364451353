@import 'main';
@import 'spritePNG';
@import 'mixins/calc';
@import 'help';

// Главный баннер
.main-banner {
  margin-bottom: 70px;
  @media(max-width: 767px) {
    margin-bottom: 15px; }

  &__first-row {
    // width: 100%
    animation: AnimationName 25s ease infinite;
    margin: 0;
    padding-top: 41px;
    padding-bottom: 41px;
    background: linear-gradient(60deg, #1422aa, #a3d3e5);
    background-size: 400% 400%;
    @keyframes AnimationName {
      0% {
        background-position: 0 82%; }
      50% {
        background-position: 100% 19%; }
      100% {
        background-position: 0 82%; } } }

  &__title {
    @include ff-roboto-bold;
    color: $orange;
    font-size: 3.75em;
    text-decoration: none;
    text-transform: none;
    @media(max-width: 400px) {
      font-size: 2.75em; } }

  &__text {
    margin-top: 15px;
    @include ff-roboto;
    font-size: 3em !important;
    font-weight: 400;
    line-height: 1.12em;
    color: #fff !important;
    @media(max-width: 400px) {
      font-size: 1.85em !important; } }

  p {
    margin-top: 15px;
    @include ff-roboto;
    font-size: 3em;
    font-weight: 400;
    line-height: 1.12em;
    color: #fff;
    @media(max-width: 400px) {
      font-size: 1.85em; } }

  &__statistic {
    margin-top: 97px; }

  &__stat-item {
    /*$indent: 44px*/
    $hb: 48px;
    padding-left: 0;
    position: relative;
    color: #fff;
    line-height: $hb;

    span {
      font-size: 1.63em;
      line-height: $hb; }

    &::before {
      @include psblock(block, absolute); }

    &--people {
      &::before {
        @include sprite($people);
        @include leftcalc($people-width, $hb);
        @include topcalc($people-height, $hb); } }

    &--coin {
      &::before {
        @include sprite($coin);
        @include leftcalc(30px, $hb);
        @include topcalc(28px, $hb); } }

    &--building {
      &::before {
        @include sprite($building);
        @include leftcalc(18px, $hb);
        @include topcalc(28px, $hb); } }

    &--percent {
      &::before {
        @include sprite($percent);
        @include leftcalc(29px, $hb);
        @include topcalc(29px, $hb); } } }

  &__info {
    width: 100%;
    padding-top: 33px;
    padding-bottom: 34px;
    box-shadow: 0 0 7px rgba(102, 102, 102, 0.21);

    .media {
      &:first-child {
        margin-top: 15px; }
      margin-bottom: 33px;

      h4 {
        margin: 0;
        font-size: 1.125em;
        line-height: 1.125em;
        color: $accent; }

      p {
        margin: 0;
        font-size: 0.875em;
        line-height: 1.285em;
        color: $headgray; } }

    &-item {
      display: block;
      margin-bottom: 33px;
      $info-pd-left: 66px;
      padding-left: $info-pd-left;

      h4 {
        margin: 0;
        font-size: 1.125em;
        line-height: 1.125em;
        color: $accent; }

      p {
        margin: 0;
        font-size: 0.875em;
        line-height: 1.285em;
        color: $headgray; }

      &::before {
        @include psblock(block, absolute); }

      &--licence {
        &::before {
          @include sprite($licence);
          @include leftcalc($licence-width, $info-pd-left);
          top: 0; } }

      &--official {
        &::before {
          @include sprite($display);
          @include leftcalc($display-width, $info-pd-left);
          top: 0; } }

      &--free {
        &::before {
          @include sprite($medal);
          @include leftcalc($medal-width, $info-pd-left);
          top: 0; } } } } }


// форма подписки
.subscribe {
  width: 100%;
  margin-bottom: 30px;
  padding: 19px 22px;
  border: 2px solid $accent;

  &__legend {
    margin: 0;
    padding: 0 0 14px 0;
    border: none;
    font-size: 1em;
    color: #000;

    span {
      @include ff-arial-bold;
      color: $accent;
      font-style: italic; } }

  &__input {
    margin-bottom: 11px;
    padding: 6px 18px;
    font-size: 1em;
    line-height: 1em;
    height: 2.5em;
    border: 1px solid #bcbcbc;
    color: $textgray;
    background-color: #fff; }

  &__check {
    margin: 22px 0 17px; }

  &__checkbox {
    &--fa {
      @include chbx-fa($accent, 10px);
      font-size: 2.5em; } }

  &__check-label {
    margin: 0 0 0 10px;
    padding: 0;
    @include ff-arial; }

  &__btn {
    margin: 0;
    padding: 9px 32px;
    font-size: 1em;
    line-height: 1em; } }

//
.article-prevs-block {
  &__1st-line {
    align-content: stretch;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    @media(max-width: 991.98px) {
      flex-wrap: wrap; } } }

// 1-я карточка-превью статьи
.article-1st {
  background-clip: content-box;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 30px;
  min-height: 400px;
  overflow: hidden;
  @media(max-width: 400px) {
    min-height: 300px; }

  &__wrap {
    display: flex;
    flex-direction: column;
    padding: 40px 100px 14px 46px;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(255, 255, 255, 0), $accent);
    @media(max-width: 400px) {
      padding: 20px 20px 14px 20px; } }

  &__title {
    flex-grow: 0;
    margin: 0 0 30px 0;
    color: #fff;
    font-size: 2.25em;
    @media(max-width: 400px) {
      font-size: 1.75em; } }

  &__link {
    color: $color-white;

    &:hover {
      color: $color-white; } }

  &__text {
    color: $color-white;
    flex-grow: 3; }

  &__statistic {
    flex-grow: 0;

    .text-muted {
      span {
        color: $color-black;
        font-size: 1em;
        text-shadow: 1px 0 3px $color-white, -1px 0 3px $color-white; } } } }

// карточки-превью статей
.article-prev {
  align-self: stretch;
  margin-bottom: 30px;
  position: relative;

  &__img-wrap {
    display: block;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 30px;
    overflow: hidden;
    @media(max-width: 991.98px) {
      margin-bottom: 15px; }

    img {
      width: 100%;
      height: auto; }

    &:hover {
      transform: scale(1.05, 1.05); } }

  &__tag {
    display: block;
    text-decoration: none;
    @include ff-arial-bold;
    color: $lightgray;
    font-size: 0.875em;
    line-height: 1.285em;

    &:hover {
      color: $lightgray; }

    &:visited {
      color: $lightgray; } }

  &__link {
    text-decoration: none; }

  &__title {
    display: inline-block;
    margin-top: 6px;
    margin-bottom: 21px;
    @media(max-width: 767px) {
      margin-top: 0;
      margin-bottom: 0; }
    @include ff-arial-bold;
    color: $headgray;
    font-size: 1.125em;
    line-height: 1.125em; }

  &__text {
    @include ff-arial;
    color: $textgray;
    font-size: 0.875em;
    line-height: 1.714em; }

  &--margin-bottom {
    margin-bottom: 60px; }

  &__stat {
    border: none;
    background: none; } }

// Заголовки разделов
.section-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  margin-top: 70px;
  margin-bottom: 27px;
  width: 100%;

  &__line {
    position: absolute;
    top: 50%;
    left: $pad;
    margin: 0;
    padding: 0;
    z-index: -1;
    width: calc(100% - (2 * #{$pad}));
    height: 1px;
    background-color: #545454; }

  &__title {
    margin: 0;
    padding: 0 15px 0 0;
    display: inline-block;
    z-index: 10;
    background-color: $color-white;
    @include ff-arial-bold;
    font-size: 1.125em;
    line-height: 1.33em;
    color: $accent;

    &:hover {
      text-decoration: underline; } }

  &__all-articles-link {
    margin: 0;
    padding: 0 0 0 15px;
    display: inline-block;
    background-color: $color-white;
    @include ff-arial;
    font-size: 1em;
    line-height: 1.5em;
    color: $accent;

    &--title {
      padding: 0; }

    &:hover {
      color: $accent; } } }


// Анонс
.announcement {
  width: 100%;
  padding-top: 30px;
  padding-bottom: 46px;
  background-color: #f3efe3;
  $day-width: 115px;

  &__title {
    margin-bottom: 46px;
    @include ff-arial-bold;
    color: $accent;
    font-size: 1.125em;
    line-height: 1.33em; }

  &__item {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px; }

  &__date {
    display: flex;
    flex-direction: column;
    align-content: center;
    position: relative;
    text-align: center;
    @include ff-arial-bold;
    color: $accent;
    font-size: 1.125em;

    &:before {
      @include psblock(block, absolute);
      @include sprite($calendar);
      @include leftcalc($calendar-width, $day-width);
      z-index: 1;
      top: 0; } }

  &__day {
    padding-top: 9px;
    width: $day-width;
    z-index: 12;
    text-align: center;
    font-size: 1.33em; }

  &__month {
    @include ff-arial;
    text-align: center;
    width: $day-width; }

  &__text {
    display: block;
    @include ff-arial-bold;
    color: $headgray;
    font-size: 1em;
    line-height: 1.5em;

    &:hover {
      color: $headgray; } } }

// FAQ
.faq {
  margin-top: 44px;

  &__section {
    margin-bottom: 21px; }

  &__item {
    margin-bottom: 32px; }

  &__tag {
    display: block;
    position: relative;
    @include ff-arial-bold;
    font-size: 0.875em;
    color: $active-color;

    span {
      padding-left: 12px; }

    &:hover {
      color: $active-color;
      cursor: pointer; } }

  &__quest {
    display: block;
    margin-top: 15px;
    margin-bottom: 4px;
    @include ff-arial-bold;
    color: #000;
    font-size: 1.125em;
    line-height: 1.2em;

    &:hover {
      color: #000;
      cursor: pointer; } } }

// Список разделов
.section-list {
  margin-bottom: 64px;

  &__group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }

  &__item {
    display: flex;
    margin-top: 22px;
    margin-right: 30px;
    padding: 15px 40px;
    border: 2px solid $accent;
    border-radius: 25px;
    font-size: 1em;
    line-height: 1em;
    color: $accent;

    &:hover {
      color: $accent; }

    &--2nd {
      border-color: $active-color;
      color: $active-color;

      &:hover {
        color: $active-color; } } } }

.contacts-content {
  margin-top: 80px;

  .nav-tabs {
    border-bottom: none;
    margin-bottom: 40px;

    li {
      margin-right: 35px;

      a {
        font-size: 20px;
        font-weight: 700;
        color: #999999;
        border-radius: 0;
        border: none;
        padding: 5px 0;

        &:hover {
          background-color: transparent;
          border: none; } } }

    li.active {
      a {
        border: none;
        color: $accent;
        border-bottom: 2px solid $accent; } } }

  .box-border {
    border-bottom: 1px solid #999;
    margin-right: 50px;
    padding-top: 30px;
    padding-bottom: 20px; }

  .box-maps {
    padding-top: 30px; }

  .box-requisites {
    p {
      padding-bottom: 20px; } }

  .box-documents {
    ul {
      li {
        margin-bottom: 15px;
        padding-left: 15px; } } } }


.contacts-footer {
  margin-top: 150px;
  @media(max-width: 767px) {
    margin-bottom: 50px; }

  p {
    padding-bottom: 20px;
    line-height: 30px;
    margin: 0; } }

.filter-tab {
  margin-top: 80px;
  position: relative;

  .btn-search {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
    background-color: $accent;
    width: 36px;
    height: 36px;
    text-align: center;
    color: $color-white; }

  .nav-tabs {
    border-bottom: none;
    margin-bottom: 40px;

    li {
      margin-right: 35px;

      a {
        font-size: 20px;
        font-weight: 700;
        color: #999999;
        border-radius: 0;
        border: none;
        padding: 5px 0;

        &:hover {
          background-color: transparent;
          border: none; } } }

    li.active {
      a {
        border: none;
        color: $accent;
        border-bottom: 2px solid $accent;
        background-color: transparent; } }

    .head-filter {
      padding-top: 0.4em; } }

  .box-border {
    border-bottom: 1px solid #999;
    margin-right: 50px;
    padding-top: 30px;
    padding-bottom: 20px; }

  .box-maps {
    padding-top: 30px; }

  .box-requisites {
    p {
      padding-bottom: 20px; } }

  .box-documents {
    ul {
      li {
        margin-bottom: 15px;
        padding-left: 15px; } } } }


.contacts-footer {
  margin-top: 150px;

  p {
    padding-bottom: 20px;
    line-height: 30px;
    margin: 0; } }

.catalog-tags {
  a {
    text-overflow: ellipsis;
    overflow: hidden; } }

.box-programm {
  box-shadow: 0 3px 5px rgba(#000000, 0.1);
  border: 1px solid transparent;
  margin-bottom: 5em;
  position: relative;
  display: flex;
  flex-direction: column;

  &_header {
    position: relative;
    padding: 1.5em 2em 1em 2em;
    overflow: hidden;
    z-index: 2;

    p {
      margin: 0;
      padding: 0; }

    .discount-ribbon {
      line-height: 40px;
      padding-left: 15px;
      margin-top: 10px;
      margin-bottom: 10px;
      position: relative;
      left: -32px;
      background: red;
      color: white;
      z-index: 10;
      display: inline-block;

      &:after {
        content: "";
        position: absolute;
        height: 0;
        width: 0;
        right: -14.5px;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-left: 15px solid red; } }

    .blur {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      background-blend-mode: overlay;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      filter: blur(3px);
      z-index: 9;
      width: 100%;
      height: 100%;
      transform: scale(1.05); }

    &.flg {
      flex-grow: 1; }

    .blur-blue {
      background-color: $accent; }

    .blur-black {
      background-color: rgba(#000000, 0.6); }

    .content {
      z-index: 100;
      color: $color-white;
      position: relative;

      h4 {
        margin-top: 2em; } }

    .star {
      position: absolute;
      top: 15px;
      right: 15px;
      z-index: 10;

      i {
        color: #FED073; }

      span {
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #333333; } } }

  .mark {
    position: absolute;
    top: -15px;
    left: 30px;
    z-index: 10;
    color: $color-white;
    padding: 0 1.5em;
    height: 30px;
    text-align: center;
    font-weight: 700;
    line-height: 30px; }

  .color-mark {
    &_blue {
      background-color: $accent; }

    &_green {
      background-color: $green; }

    &_red {
      background-color: red; }

    &_orange {
      background-color: #FED073; }

    &_lightblue {
      background-color: #AED3E5; } }

  &_main {
    padding: 0.5em 1em 0.5em 2em;
    color: $accent;
    background-color: $color-white;
    z-index: 2;
    flex-grow: 1; }

  &_footer {
    display: flex;
    justify-content: space-between;
    padding: 1.8em 1em 1em 1em;
    z-index: 2;

    .left-content {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      position: relative;

      .fa-ul {
        color: #5A5A5A;

        li {
          margin-bottom: 5px; }

        .fa-li {
          i {
            color: #fed07f; } } }

      .discount-ribbon {
        line-height: 40px;
        padding-left: 15px;
        margin-bottom: 10px;
        position: relative;
        left: -26px;
        background: red;
        color: white;

        &:before, &:after {
          content: "";
          position: absolute; }

        &:before {
          height: 0;
          width: 0;
          top: -8.5px;
          left: 0.1px;
          border-bottom: 9px solid darkred;
          border-left: 9px solid transparent; }

        &:after {
          height: 0;
          width: 0;
          right: -14.5px;
          border-top: 20px solid transparent;
          border-bottom: 20px solid transparent;
          border-left: 15px solid red; } } }

    .right-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .img-wrap {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
        overflow: hidden;
        width: 100px;

        .img-circle {
          flex: 1 40px !important;
          overflow: hidden;
          margin: 4px;
          border-radius: 0;

          img {
            border-radius: 50%;
            width: 100%;
            height: 100%;
            object-fit: cover; }

          span {
            overflow: hidden;
            display: block;
            width: 40px;
            height: 40px;
            text-align: center;
            line-height: 38px;
            border: 2px solid #999999;
            border-radius: 50%; } }

        .img-circle:nth-child(odd) {
          flex: 0 40px; }

        .img-circle:nth-child(even) {
          flex: 0 40px; } }

      .rating {
        margin-top: 5px;
        color: #fed07f;
        font-size: 12px; } } }

  &_button {
    position: absolute;
    bottom: -60px;
    left: -1px;
    right: 0;
    width: calc(100% + 2px);
    opacity: 0;
    background-color: $color-white;

    .wrap-button {
      padding: 0.8em 1.5em;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .button-primary {
        display: block;
        border-radius: 100px;
        /*padding: 0.8em 1.5em*/
        font-size: 14px;
        color: $color-white;
        padding: 1em 2em;
        background-color: $accent;
        @include mt(0.25s);

        &:hover {
          background: $light-blue; } }

      .button-default {
        display: block;
        padding: 0.8em 1.5em;
        font-size: 14px;
        background-color: #F6F6F6;
        border: 1px solid #BCBCBC;
        border-radius: 100px;
        color: #000000;

        &:hover {
          text-decoration: none; } } } }

  &:hover {
    border: 1px solid $accent;

    .box-programm_button {
      z-index: 11;
      opacity: 1;
      box-shadow: 0 3px 5px rgba(#000000, 0.1);
      border-left: 1px solid $accent;
      border-right: 1px solid $accent;
      border-bottom: 1px solid $accent; } }

  .scene {
    width: 100%;
    height: 100%;
    border: none;
    perspective: 600px;

    .card {
      width: 100%;
      height: 100%;
      transition: transform 1s;
      transform-style: preserve-3d;
      cursor: pointer;
      position: relative;

      &.is-flipped {
        transform: rotateY(180deg); } }

    .card__side {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      overflow: hidden;

      .fas {
        color: blue;
        text-align: right; }

      &.front {
        background: #FED073;
        padding: 30px;

        .h2-blue {
          text-align: left !important; } }

      &.back {
        background: white;
        transform: rotateY(180deg);
        padding: 30px;
        border: 2px solid $orange;

        form {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          height: 100%; } } } } }

.zero_results_form {
  border: 2px solid blue;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;

  .zero_results_input {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .form-control {
      border: 1px solid grey; }

    .input_theme {
      flex-grow: 2; }

    .input_name {
      flex-grow: 1;
      margin: 0 15px; }

    .input_tel {
      flex-grow: 1; }

    @media(max-width: 767px) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .input_name {
        margin-left: 0;
        margin-right: 0; }

      .input_theme, .input_name {
        margin-bottom: 10px; } } } }

.box-privileges {
  position: relative;
  background-color: #E8E8E8;
  border-radius: 20px;
  padding-top: 40px;
  padding-right: 100px;
  padding-left: 100px;
  padding-bottom: 40px;

  @media(max-width: 767px) {
    padding: 40px 40px;
    overflow: hidden;
    margin-bottom: 4em; }

  a, p {
    position: relative; }

  h2 {
    color: $accent;
    font-size: 40px;
    position: relative; }

  ul {
    padding-left: 15px;
    margin-bottom: 2em;
    position: relative;

    li {
      font-size: 18px;
      list-style: none;

      &::before {
        content: "\2022";
        font-size: 20px;
        padding-right: 10px;
        color: $accent; } } }

  .percent {
    position: absolute;
    bottom: -80px;
    right: 40px;
    font-size: 200px;
    color: $color-white;
    z-index: 0; } }

.box-privileges_gray {
  background-color: #E8E8E8; }

.box-privileges_blue {
  background-color: #AED3E5; }

.exclusive {
  background-color: #3d4852;
  background-image: url("/img/programs/foto_vselennaya.jpg");
  background-size: cover;
  background-position: right;
  margin-bottom: 4em;
  padding: 2em 4em;
  @media(max-width: 767px) {
    padding: 2em 2em;
    h2 {
      color: white;
      padding-bottom: 1em; }
    form {
      margin-bottom: 1em; } } }


.button-group {
  display: flex;
  flex-wrap: wrap;
  margin-top: 4em;

  .btn {
    margin-right: 25px;
    margin-bottom: 25px; } }

//
.s-header-program {
  background-color: rgba(#000, 0.5);
  background-blend-mode: overlay;
  background-repeat: no-repeat;
  background-size: cover;
  color: $color-white;
  padding: 3em 0;

  .fa-ul {
    display: flex;
    justify-content: space-between;
    padding-left: 25px;
    margin: 0; } }


.number-box {
  &_item {
    text-align: center;

    span {
      font-size: 60px; } } }

.s-training {
  background-color: rgba(#000, 0.7);
  background-blend-mode: overlay;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 8em 0 3em 0;
  padding: 4em 0 10em 0;

  .training-box {
    text-align: center;
    margin-top: 4em;

    span {
      display: flex;
      width: 40px;
      height: 40px;
      line-height: 40px;
      justify-content: center;
      background-color: $color-white;
      border-radius: 50%;
      color: $accent;
      font-size: 18px;
      font-weight: 700;
      margin: 0 auto; }

    &_img {
      width: 150px;
      height: 150px;
      margin: 0.5em auto;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain; } }

    p {
      color: $color-white; } } }

.brochure-box {
  background-color: #FECF73;
  border-radius: 21px;
  padding: 2em 4em 3em 4em;
  margin: 2em 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  &_text {
    p {
      margin: 1.2em 0; } }

  @media(max-width: 400px) {
    padding: 15px; }

  &_img {
    img {
      width: 120px;
      height: auto; } } }

.s-time-costs {
  padding: 2em 2em;
  margin-bottom: 5em;
  box-shadow: 0 0 10px rgba(#000000, 0.2);

  ol {
    padding-left: 15px; } }

.s-learning-outcome-doc {
  background-blend-mode: overlay;
  background-repeat: no-repeat;
  background-size: cover;

  .learning-outcome-doc {
    margin-top: 2em;
    margin-bottom: 2em;
    display: flex;
    align-items: center;
    justify-content: space-around;

    &_img {
      width: 20%; }

    &_desc {
      padding-top: 1em;
      width: 80%; } }

  @media(max-width: 1024px) {
    .learning-outcome_desc {
      padding: 0.5em; }

    .learning-outcome-doc_img {
      width: 40%; } }

  @media(max-width: 640px) {
    .learning-outcome-doc {
      margin-top: 2em;
      display: inline-block;
      align-items: center; }

    .learning-outcome-doc_img {
      width: 100%; }

    .learning-outcome_desc {
      padding: 1em;
      width: 100%; } } }

.s-learning-outcome {
  background-color: rgba(#000, 0.7);
  background-blend-mode: overlay;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 5em 0;
  padding: 4em 0 7em 0;

  .learning-outcome {
    margin-top: 3em;
    display: flex;
    align-items: center;

    &_img {
      width: 50%; }

    &_desc {
      padding: 2em;
      width: 50%;
      color: $color-white; } }

  @media(max-width: 767px) {
    .learning-outcome_desc {
      padding: 0.5em; } }

  @media(max-width: 400px) {
    .learning-outcome {
      margin-top: 3em;
      display: inline-block;
      align-items: center; }
    .learning-outcome_desc {
      padding: 1em;
      width: 100%; } } }

.s-portfolio-training {
  box-shadow: 0 0 10px rgba(#000000, 0.3);
  padding: 3em 5em;
  margin-top: 2em;
  margin-bottom: 2em;
  @media(max-width: 400px) {
    padding: 1em 1em; }


  ol {
    column-count: 2;
    column-gap: 40px;
    margin: 0;
    padding-left: 15px; } }

.carousel-author-program {
  .item-author {
    padding: 2em 8em;
    display: flex;
    align-items: center;

    &_img {
      width: 120px;
      min-width: 120px;
      height: 120px;
      border-radius: 50%;
      overflow: hidden;
      box-shadow: 0 0 0 20px #f2f2f2;
      margin-right: 2.5em;
      padding: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover; } } }

  @media(max-width: 400px) {
    .item-author {
      display: inline-block;
      padding: 1em 1em; } } }

.s-free-consultation {
  background-repeat: no-repeat;
  background-size: cover;
  padding: 4em 0 18em 0;
  margin-top: 10em;

  h2 {
    font-size: 70px;
    font-weight: 900;
    padding-top: 1.5em; } }

.stars {
  color: $highlight; }

.payment_methods {
  .nav-pills {
    li {
      border-radius: 0;

      &.active {
        a, a:focus, a:hover {
          color: $color-white;
          background-color: $accent; } }

      a {
        overflow: hidden;
        height: 100%;
        padding: 2em 0 0 0; } } }

  .tab-pane {
    border-radius: 0;

    &.active {
      .panel {

        border-radius: 0; } } } }

ul {
  &.list-spaced {
    li {
      border-bottom: 1px solid #CCC;
      padding-bottom: 15px !important;
      padding-top: 15px !important;

      .btn {
        margin-left: 5px;
        margin-top: 0; } } } }

.flip-card-wrap {
  width: 100%;
  height: 100%;
  border: none;
  perspective: 600px;

  .card {
    width: 100%;
    height: 100%;
    transition: transform 1s;
    transform-style: preserve-3d;
    cursor: pointer;
    position: relative;
    min-height: 500px;

    &.is-flipped {
      transform: rotateY(180deg); }

    .h3_card {
      font-weight: 700 !important;
      font-family: Roboto, sans-serif !important;
      font-size: 24px !important;
      font-style: normal; }

    p {
      font-style: normal; } }

  .card__side {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: hidden;

    &.front {
      //background: #FED073
      //padding: 30px
      form {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 100%;
        overflow: auto;
        margin-bottom: 5px; }

      .h2-blue {
        text-align: left !important; } }

    &.back {
      background: white;
      transform: rotateY(180deg);
      padding: 30px;
      border: 2px solid #1422aa; }

    &.after {
      background: white;
      //transform: rotateY(180deg)
      padding: 20px;
      border: 2px solid #1422aa; }

    &.before-auth {
      background: white;
      padding: 20px;
      border: 2px solid #1422aa;

      .h2-blue {
        text-align: left !important;
        font-size: 24px; } } } }


@import 'front_article';
@import 'front_about';
@import 'front_study';

// Всегда в конце
@import 'media';
