@import 'vars';

/*==========  Desktop First  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1199.98px) {
  header {
    .s-header {
      padding: 40px 0;

      .logo {
        img {
          width: 60px;
          height: auto;
          float: left;
          margin-right: 10px; } }

      .logo-title {
        font-size: 15px;
        font-family: $headline-font;
        display: inline-block;

        span {
          font-weight: 400;
          font-size: 10px;
          font-family: $default-font;
          text-transform: uppercase;
          letter-spacing: 0.60em; } } } }
  .text-columns-5 {
    column-count: 5; }
  .text-columns-2 {
    column-count: 2; }
  .s-header {
    .logo {
      img {
        width: 70px;
        height: auto; } } }
  .s-facts {
    .facts-grid {
      display: grid;
      width: 100%;
      height: auto;
      grid-template-areas: "item-1 item-2 item-3 item-4" "item-5 item-2 item-6 item-7" "item-8 item-9 item-11 item-7" "item-8 item-10 item-11 item-12"; } }

  .menu-block {
    margin-top: 20px;
    margin-bottom: 10px;

    .menu-block-link {
      a.btn-white-min {
        font-size: 12px; } }

    .group-menu-block {
      display: flex;
      justify-content: space-between;

      .menu-block-users {
        display: flex;
        align-items: center; }

      .menu-block-geo {
        a {
          display: flex;
          align-items: center;
          color: #ffffff;

          &:hover {
            text-decoration: none;
            color: $orange; } } } }

    i {
      font-size: 20px; }

    span {
      font-size: 16px;

      small {
        font-size: 13px; } } }

  .s-loyalty {
    .item-loyalty {
      span {
        font-size: 1.5em; } } }

  // Стили для страницы ОБУЧЕНИЕ
  .study-wrap {
    .panel-body {
      a.btn-block {
        font-size: 0.8em; } } }

  // Стили для страницы О проекте СЕМИНАРА
  .seminar-about {
    &-head {
      h1 {
        font-size: 3.4em;
        margin-top: 1em;
        margin-bottom: 0.2em; } }

    &-medal {
      margin-bottom: 1em;
      display: flex;
      flex-wrap: nowrap; }

    &-sertificat {
      height: 100%;
      margin-top: 11em; } } }

// Medium Devices, Desktops
@media only screen and (max-width: 991.98px) {
  .text-columns-5 {
    column-count: 4; }
  .text-columns-2 {
    column-count: 2; }
  .s-header {
    background-color: #ffffff;

    .logo {
      img {
        width: 70px;
        height: auto; } }
    // Кнопка телефона
    .header-phone {
      .header-phone-number {
        margin: 0; }

      .number {
        font-size: 0;
        width: 50px;
        height: 50px;
        display: block;
        border: 2px solid $accent;
        position: relative;
        border-radius: 50%;
        transition: all 0.3s;
        text-decoration: none;

        &::before {
          font-family: $font-awesome;
          font-weight: 900;
          content: "\f095";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 18px;
          color: $accent; }

        &:hover {
          background-color: $accent;

          &::before {
            color: #ffffff; } } } }
    // Обертка двух кнопок
    .btn-wrap {
      display: flex;
      justify-content: space-around;
      // Кнопка личного кабинета
      .header-btn {
        position: relative;

        .btn-login {
          width: 50px;
          height: 50px;
          display: block;
          border: 2px solid $accent;
          position: relative;
          text-decoration: none;

          &::before {
            content: '\f084';
            font-family: $font-awesome;
            font-weight: 900;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-repeat: no-repeat;
            color: $accent; }

          &:hover {
            &::before {
              color: #ffffff; } } }

        .btn-user {
          padding: 0 !important;
          width: 50px;
          height: 50px; }

        // Стили подменю зарегистрированного профиля
        button {
          z-index: 25 !important; }

        .btn-group {
          position: relative;
          display: block;

          span.caret {
            display: none; }

          .dropdown-menu {
            position: absolute;
            left: -220%;
            top: 55px;

            li:first-child {
              padding-top: 5px; }

            li {
              padding: 2px 0;

              i {
                margin-left: 7px;
                color: $accent; } }

            li.divider {
              padding: 0;
              margin: 5px 0; }

            a {
              padding: 10px 20px; } } }

        .btn-group.open {
          button {
            background-color: #f9b233 !important; } } }

      // Кнопка количества заявок
      .header-cart {
        position: relative !important;

        a {
          font-size: 0; }

        .min-app {
          width: 50px;
          height: 50px;
          display: block;
          border: 2px solid $accent;
          position: relative;
          border-radius: 50%;
          text-decoration: none;

          &::before {
            content: '\f15c';
            font-family: $font-awesome;
            font-weight: 900;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 18px;
            color: $accent; }

          &:hover {
            background-color: $accent;

            &::before {
              color: #ffffff; } }

          span {
            font-size: 12px;
            padding: 0;
            margin: 0;
            display: block;
            position: absolute;
            top: -5px;
            right: -7px;
            width: 22px;
            height: 22px;
            line-height: 22px;
            background-color: $accent;
            color: #ffffff;
            border-radius: 50%; } } } } }

  .s-facts {
    .facts-grid {
      display: grid;
      width: 100%;
      height: auto;
      grid-template-areas: "item-1 item-2 item-3" "item-5 item-2 item-6" "item-8 item-9 item-11" "item-8 item-7 item-12" "item-4 item-7 item-10";

      .item {
        position: relative;

        > div {
          opacity: 1; } } } }

  // Стили боковой панели в разделе заявки в личном кабинете
  .request-wrap {
    .left-aside-bar {
      h4 {
        position: relative;
        padding: 10px;
        text-align: center {
          background-color: transparent; }

        &:first-child {
          padding: 10px 0 10px 0px;
          margin: 0; }

        i {
          font-size: 30px;
          text-align: center;
          display: inline-block; } }

      a.list-group-item {
        border: none;
        background-color: transparent;
        position: relative;
        transition: all 0.2s linear;
        color: #666666;
        padding: 20px 0 15px 0;
        text-align: center;

        &:hover {
          background-color: transparent;
          color: #666666; }

        span.badge {
          width: 18px;
          height: 18px;
          border-radius: 50%;
          line-height: 18px;
          padding: 0;
          margin: 0;
          position: absolute;
          right: 5px;
          background-color: $accent;
          top: 15px;
          font-size: 10px; }

        i {
          font-size: 22px; } }

      a.list-group-item.active {
        color: #000000;
        background-color: transparent;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 5px;
          height: 100%;
          background-color: $active-color; }

        &:hover {
          color: #666666; } } } }

  footer {
    .footer-bottom {
      .footer-links {
        padding-bottom: 20px;
        padding-top: 20px;

        ul {
          li {
            display: block;
            margin-right: 0px; } } }

      .copy {
        text-align: left; } } }

  // Шаги в списке слушателей и в корзине
  .razvitum-steps {
    ol {
      li {
        text-align: center;

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 95%;
          height: 1px;
          display: block;
          background-color: #000; }

        span {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          padding-right: 0px;

          i.counter {
            margin-right: 0px;
            margin-bottom: 5px; } } } } }

  // Стили для страниц СЕМИНАРА
  .seminar-header {
    h3 {
      font-size: 32px; }

    .text {
      span {
        font-size: 14px; }

      p {
        font-size: 20px; } } }


  .seminar-block-text {
    .btn-white {
      font-size: 0.8em; }

    p {
      font-size: 1.2em;

      span {
        text-transform: uppercase;
        font-size: 1.2em;
        font-weight: 700; } } }

  // Стили для страницы ОБУЧЕНИЕ
  .study-wrap {
    .panel-body {
      a.btn-block {
        font-size: 1em; } } }

  // Стили для страницы О проекте СЕМИНАРА
  .seminar-about {
    &-head {
      h1 {
        font-size: 3.4em;
        margin-top: 1em;
        margin-bottom: 0.2em; } }

    &-medal {
      margin-bottom: 1em;
      display: flex;
      flex-wrap: nowrap; }

    &-sertificat {
      height: 100%;
      margin-top: 2em;
      margin-bottom: 2em;
      text-align: left; } }


  .lms-menu-wrap {
    position: relative;

    .lms-menu {
      margin-top: 10px;
      margin-bottom: 0px;
      width: 100%;
      overflow: hidden;
      transition: width 0.1s linear;

      #btn-menu-bars {
        cursor: pointer; }

      i {
        font-style: normal; }

      ul.fa-ul {
        display: flex;

        li {
          margin-right: 3.7em;
          position: relative; } }

      .collapse-wrap {
        position: relative;
        background-color: #ACACAC;
        position: relative;
        margin-top: 2.5em !important; }

      .final-test {
        border: none !important;
        padding: 0; } } } }


// Small Devices, Tablets
@media only screen and (max-width: 767.98px) {
  .mt-sm-4 {
    margin-top: 4em !important; }
  .mt-sm-3 {
    margin-top: 3em !important; }

  header {
    .s-header {
      padding-top: 18px !important;
      background: #fff;

      .navbar-toggle .icon-bar {
        width: 100% !important; }

      .logo-wrap {
        a.logo {
          padding: 0; }

        .logo {
          margin: 0 !important;
          display: block;
          padding-top: 10px;
          position: fixed;
          top: 5px;
          left: 10px;
          z-index: 20;

          img {
            width: 50px !important;
            height: auto;
            float: none;
            margin-right: 0px; } }

        .logo-title {
          font-size: 16px;
          font-family: $headline-font;
          display: block;
          padding-top: 5px;
          display: none;

          span {
            font-weight: 400;
            font-size: 10px;
            font-family: $default-font;
            text-transform: uppercase;
            letter-spacing: 0.55em;
            text-align: center; } } }
      // Кнопка телефона
      .header-phone {
        position: fixed;
        top: 5px;
        right: 135px;
        z-index: 20; }

      .btn-wrap {
        // Кнопка количества заявок
        .header-cart {
          position: fixed !important;
          display: block;
          top: 5px !important;
          right: 195px !important;
          z-index: 20;
          left: unset !important;
          transform: translateX(0) !important;
          height: 50px; }
        // Кнопка личного кабинета
        .header-btn {
          position: fixed;
          top: 5px;
          right: 75px;
          z-index: 20; } } } }

  .text-columns-5 {
    column-count: 2; }
  .text-columns-2 {
    column-count: 1; }

  .s-story {
    padding-bottom: 50px;

    .text {
      padding-bottom: 30px;
      font-size: 18px; }

    .bg-wrap {
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;

      .col-6 {
        width: 50%; }

      .item-story {
        width: 100%; } } }

  .dropdown-menu {
    position: relative; }
  .navbar-collapse {
    overflow-y: scroll;
    height: 100vh; }
  .menu-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 19;
    background: #fff !important;

    .navbar.navbar-default {
      background-color: #ffffff;
      border-bottom: 1px solid $accent; }

    .navbar-default .navbar-nav .open .dropdown-menu > li > a {
      color: #ffffff; }

    .dropdown-menu > li > a:focus, .dropdown-menu > li > a:hover {
      background-color: transparent !important;
      background-image: none !important; }

    .buttom-menu {
      background-color: $accent;

      .container {
        padding: 0; }

      ul {
        text-align: left !important;

        li {
          width: 100%;

          a {
            color: #ffffff;
            text-transform: uppercase;
            text-align: left; } } } }

    .navbar {
      min-height: 60px; }

    .navbar-toggle {
      position: fixed;
      z-index: 999;
      top: 5px;
      right: 15px;
      margin: 0;
      background-color: $accent;
      border: 2px solid $accent;
      border-radius: 50%;
      width: 50px;
      height: 50px;

      &:hover, &:focus {
        background-color: #ffffff;

        .icon-bar {
          background-color: $accent; } }

      .icon-bar {
        background-color: #ffffff; } } }
  .menu-wrap .buttom-menu .nav > li > a:focus, .menu-wrap .buttom-menu .nav > li > a:hover,
  .menu-wrap .buttom-menu .nav > li > a:focus, .menu-wrap .buttom-menu .nav > li:hover {
    background-color: transparent !important; }

  .s-facts {
    .facts-grid {
      display: grid;
      width: 100%;
      height: auto;
      grid-template-areas: "item-1 item-2" "item-5 item-2" "item-8 item-9" "item-8 item-7" "item-4 item-7" "item-10 item-3" "item-11 item-6" "item-11 item-12";

      .item {
        position: relative;

        > div {
          opacity: 1; } } } }
  footer {
    .footer-bottom {
      .copy {
        text-align: left;
        margin-top: 10px; } } }

  // Шаги в списке слушателей и в корзине
  .razvitum-steps {
    ol {
      li {
        text-align: center;

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 95%;
          height: 1px;
          display: block;
          background-color: #000; }

        span {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          padding: 0 10px;
          font-size: 12px;

          i.counter {
            margin-right: 0px;
            margin-bottom: 5px; } } } } }

  // Стили боковой панели в разделе заявки в личном кабинете
  .request-wrap {
    position: relative;

    .btn-request {
      display: block;
      position: absolute;
      left: 10px;
      top: 20px;
      z-index: 15;

      i {
        font-size: 18px; } }

    .left-aside-bar {
      h4 {
        text-align: left;
        background-color: transparent;
        padding: 10px 5px 10px 65px;

        &:first-child {
          padding: 27px 0 10px 65px; } }

      a.list-group-item {
        color: #666666;
        padding: 10px 0 10px 15px;
        margin-left: 60px;
        text-align: left;

        &:hover {
          background-color: $active-color;
          color: #666666; }

        span.badge {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          line-height: 24px;
          padding: 0;
          margin: 0;
          position: absolute;
          right: 10px;
          background-color: $accent;
          top: 50%;
          transform: translateY(-50%);
          font-size: 13px; } }

      a.list-group-item.active {
        color: #000000;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 5px;
          height: 100%;
          background-color: $active-color; } } }

    .request-content {
      &-head {
        h2, p {
          padding-left: 20px; } } } }

  // Стили для страниц из раздела О Нас
  .concept-wrap {
    .column {
      padding-top: 20px;
      padding-bottom: 20px;
      column-width: fill-available;
      column-count: 1; } }

  // Стили для страницы Общая информация в ЗАЯВКЕ
  .summary-wrap {
    .item-summary {
      .situations-wrap {
        .item-situations {
          width: 45%;
          padding: 15px;
          margin: 5px;

          img {
            width: 80px;
            height: auto; }

          p {
            padding-top: 15px;
            font-size: 16px; } } } } }

  // Стили для страницы Список слушателей в ЗАЯВКЕ
  .listeners-wrap {
    .listener-item {
      .programm {
        margin-top: 20px; }

      .status {
        padding-left: 2em; } } }


  // Стили для страниц СЕМИНАРА
  .seminar-wrap {
    h1 {
      font-size: 46px; } }

  .s-loyalty {
    .item-loyalty {
      span {
        font-size: 1.5em; } } }

  // Стили для страниц СЕМИНАРА
  .seminar-header {
    h3 {
      font-size: 32px; }

    .text {
      text-align: center !important;
      margin-bottom: 20px;
      margin-top: 20px;

      span {
        font-size: 14px; }

      p {
        font-size: 20px; } } }

  .study-cases {
    .cases-list {
      column-count: 1; } }

  .menu-block {
    margin-top: 20px;

    .menu-block-link {
      width: 100%;

      a.btn-white {
        display: inline-block;
        margin-right: 0px; } }

    .menu-block-users {
      margin-top: 20px;
      margin-right: 15px;
      margin-bottom: 20px; }

    .menu-block-geo {
      margin-top: 20px;
      margin-bottom: 20px; }

    i {
      font-size: 30px;
      margin-right: 10px;
      margin-left: 0px; } }


  .seminar-wrap {
    .seminar-carousel {
      .carousel-inner {
        .item-wrap {
          display: flex;
          flex-direction: column;
          width: 100%;

          &-img {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            width: 100%;

            img {
              border-radius: 50%;
              margin: 20px 10px 10px;
              width: 150px;
              height: 150px;
              box-shadow: 0 0 0 20px #f2f2f2; }

            p {
              color: $accent;
              font-size: 14px;
              padding: 0 15px;
              text-align: center; } }

          &-text {
            width: 100%;
            padding: 15px; } } } } }


  .recommend-colleague {
    .letter {
      &-edit {
        &-bg {
          position: absolute;
          width: 180px;
          height: auto;
          opacity: 0.2;
          bottom: 5%;
          right: 3%; }

        &-marka {
          text-align: left; } } } }

  .semitar-interactive-maps {
    .static-text-map {
      margin-top: 20px; } }

  #modal-stock {
    .modal-body {
      .item {
        position: relative;

        &::before {
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(#8ec6ff, 0.9); } }

      .modal-custom-form {
        .datepicker {
          .input-date, .input-time {
            margin-bottom: 15px; } } } } }


  .lms-menu-wrap {
    position: relative;

    .lms-menu {
      margin-top: 10px;
      margin-bottom: 0px;
      width: 100%;
      overflow: hidden;
      transition: width 0.1s linear;

      #btn-menu-bars {
        cursor: pointer; }

      i {
        font-style: normal; }

      ul.fa-ul {
        display: flex;

        li {
          margin-right: 2.5em; } }

      .collapse-wrap {
        background-color: #ACACAC;
        position: relative;
        margin-top: 2.5em !important; }

      .final-test {
        border: none !important;
        padding: 0; } } }

  .seminar-about {
    &-sertificat {
      height: 100%;
      margin-top: 16.5em;
      text-align: left; } }


  .vz-header {
    padding: 5px 0;

    .logo-wrap {
      .logo {
        img {
          width: 50px; } } }

    .header-phone-icon {
      width: 50px;
      height: 50px;
      font-size: 1.5em; } } }


// Extra Small Devices, Phones
@media only screen and (max-width: 575.98px) {

  .text-columns-5 {
    column-count: 1; }
  .text-columns-2 {
    column-count: 1; }

  .s-story {
    .bg-wrap {
      display: flex;
      flex-wrap: wrap;

      .col-6 {
        width: 100%;
        margin: 0 auto; }

      .item-story {
        width: 100%;
        height: auto; } } }

  .s-facts {
    .facts-grid {
      display: grid;
      width: 100%;
      height: auto;
      grid-template-areas: "item-1" "item-2" "item-2" "item-3" "item-4" "item-5" "item-7" "item-7" "item-9" "item-6" "item-10" "item-8" "item-8" "item-11" "item-12";

      .item {
        position: relative;

        > div {
          opacity: 1; } } } }

  // Шаги в списке слушателей и в корзине
  .razvitum-steps {
    ol {
      li {
        span {
          padding: 0 10px;
          font-size: 10px;

          i.counter {
            margin-right: 0px;
            margin-bottom: 5px; } } } } }

  // Стили для страницы Общая информация в ЗАЯВКЕ
  .summary-wrap {
    .item-summary {
      .situations-wrap {
        .item-situations {
          width: 95%;
          padding: 15px;
          margin: 5px;

          img {
            width: 80px;
            height: auto; }

          p {
            padding-top: 15px;
            font-size: 16px; } } } } }

  // Стили для страниц СЕМИНАРА
  .seminar-wrap {
    h1 {
      font-size: 36px; }

    .vz-banner {
      h1 {
        font-size: 1.5em; }

      h2 {
        font-size: 1.8em !important; } } }

  .s-loyalty {
    .item-loyalty {
      span {
        font-size: 1em; } } }

  // Стили для страницы О проекте СЕМИНАРА
  .seminar-about {
    &-head {
      h1 {
        font-size: 2.1em;
        margin-top: 1em;
        margin-bottom: 0.2em; } }

    &-medal {
      margin-bottom: 1em;
      display: flex;
      flex-wrap: nowrap;

      .item-medal {
        position: relative;

        span {
          position: absolute;
          bottom: 26px;

          text-align: center;
          font-size: 1em;
          color: #ffffff;
          display: block;

          &::after {
            content: 'ак.ч.';
            position: absolute;
            left: -3px;
            bottom: -10px;
            font-size: 0.5em; } }

        img {
          width: 100px;
          height: auto; } } }

    &-sertificat {
      height: 100%;
      margin-top: 2em;
      margin-bottom: 2em; } }

  .s-workshop-participant {
    .box-workshop-participant {
      text-align: center;
      margin: 1.5em 0; } }

  .lms-menu-wrap {
    position: relative;

    .lms-menu {
      margin-top: 10px;
      margin-bottom: 0px;
      width: 100%;
      overflow: hidden;
      transition: width 0.1s linear;

      #btn-menu-bars {
        cursor: pointer; }

      i {
        font-style: normal; }

      ul.fa-ul {
        display: flex;

        li {
          margin-right: 1.6em; } }

      .collapse-wrap {
        background-color: #ACACAC;
        position: relative;
        margin-top: 2.5em !important; }

      .final-test {
        border: none !important;
        padding: 0; } } } }


// Custom, iPhone Retina */
@media only screen and (max-width: 414.98px) {
  .pull-md-right {
    float: none !important; }

  .programs__item-actions {
    .clearfix {
      .pull-right {
        float: left !important;
        margin-top: 15px; } } }

  // Стили для страниц СЕМИНАРА
  .seminar-wrap {
    .vz-banner {
      h1 {
        font-size: 1.5em; }

      h2 {
        font-size: 1.8em !important; } } }
  .vz-wrap {
    .btn-white {
      font-size: 0.8em; } }

  .lms-menu-wrap {
    position: relative;

    .lms-menu {
      margin-top: 10px;
      margin-bottom: 0px;
      width: 100%;
      overflow: hidden;
      transition: width 0.1s linear;

      #btn-menu-bars {
        cursor: pointer; }

      i {
        font-style: normal; }

      ul.fa-ul {
        display: flex;

        li {
          margin-right: 1.5em; } }

      .collapse-wrap {
        background-color: #ACACAC;
        position: relative;
        margin-top: 2.5em !important; }

      .final-test {
        border: none !important;
        padding: 0; } } } }

// Small Devices, Tablets
@media only screen and (min-width: 768px) {
  .nav-justified > li {
    vertical-align: middle; }

  #modal-stock {
    .modal-dialog {
      width: 750px; } }
  .modal-md {
    width: 460px;

    .modal-body {
      padding: 30px; } }

  .position-xl-absolute {
    position: relative; } }

// Medium Devices, Desktops
@media only screen and (min-width: 992px) {
  .md-sticky {
    position: sticky;
    top: 0; }

  .position-xl-absolute {
    position: absolute; } }
