@import 'fonts';
@import 'vars';

body, html {
  font-family: $default-font;
  line-height: 1.65;
  font-size: 16px !important; }

body {
  counter-reset: custom-counter-1 custom-counter-2; }

::input-placeholder {
  color: $gray; }

::placeholder {
  color: $gray; }

.wrap {
  line-height: 1.65;
  padding: 40px 15px; }

.desc {
  font-size: 18px;
  font-weight: 500;
  padding: 10px 0 20px; }

.h1 {
  color: $accent;
  font-family: $headline-font;
  font-size: 36px;
  padding-bottom: 20px; }

.h2 {
  font-family: $headline-font;
  font-size: 40px;
  padding: 30px 0 15px;
  text-align: center; }

.h2-blue {
  font-size: 32px;
  font-family: $default-font !important;
  text-align: center;
  padding: 10px 0;
  color: $accent;
  font-weight: 700; }

.h4 {
  font-family: $default-font !important;
  font-size: 1.1em;
  color: $gray;
  font-weight: 700; }

.h4-blue {
  color: $accent;
  font-family: $default-font !important;
  font-size: 1.1em;
  font-weight: 500;
  text-transform: uppercase; }

.h5-blue {
  text-transform: uppercase;
  font-family: $default-font !important;
  font-weight: 700;
  color: $accent;
  font-size: 1em; }

.h5-gray {
  text-transform: uppercase;
  font-family: $default-font !important;
  font-weight: 700;
  color: $gray;
  font-size: 16px; }

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: $headline-font; }

.z-index {
  z-index: 20 !important; }

.menu-wrap {
  .buttom-menu {
    li.active {
      background-color: $active-color;

      a {
        color: #000000 !important; } } } }

.form-control {
  padding: 0 20px;
  color: #333;
  height: 50px;
  background: #f0f0f0;
  font-size: 16px;
  border: 1px solid #f0f0f0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;

  &.input-sm {
    height: 30px;
    font-size: 14px; } }

.input-group-addon {
  border: none;
  border-radius: 0;
  background-color: $lgray;

  &:first-child {
    border-right: 1px solid $lgray; } }

input[type=date]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none; }

.modal-content {
  border-radius: 0; }

.counter-1::before {
  counter-increment: custom-counter-1;
  content: counter(custom-counter-1) ". "; }

.counter-2::before {
  counter-increment: custom-counter-2;
  content: counter(custom-counter-2) ". "; }

.counter-list {
  counter-reset: custom-counter-3;

  .counter-item::before {
    counter-increment: custom-counter-3;
    content: counter(custom-counter-3) ". "; } }

.listener-item-programs {
  counter-reset: custom-counter-4;

  .counter-noi::before {
    content: counter(custom-counter-4); }

  .counter::before {
    counter-increment: custom-counter-4;
    content: counter(custom-counter-4); } }

.counter-listeners {
  counter-reset: custom-counter-listeners;

  li {
    list-style: none;
    margin-bottom: 3em;
    margin-top: 2em;

    &::before {
      display: inline-block;
      margin-bottom: 1em;
      counter-increment: custom-counter-listeners;
      content: "Слушатель №" counter(custom-counter-listeners) " ";
      font-weight: bold; } } }

// Особые чекбоксы
// https://bootsnipp.com/snippets/featured/simple-css-custom-checkboxes-and-radios
.form-check {
  label {
    cursor: pointer;
    position: relative;
    padding-left: 1.7em; }

  input {
    &[type="checkbox"], &[type="radio"] {
      position: absolute;
      opacity: 0;
      left: -15px; }

    &[type="checkbox"] {
      + .label-text {
        font-weight: normal; }

      + .label-text:before {
        content: "\f111";
        font-family: "Font Awesome 5 Free";
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        font-size: 120%;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        width: 1em;
        display: inline-block;
        margin-right: 0.4em;
        margin-left: -1.4em;
        color: #999999;
        background-color: #FFFFFF;
        border-radius: 50%; }

      &:checked + .label-text:before {
        content: "\f058";
        color: $accent;
        animation: effect 250ms ease-in; }

      &:disabled + .label-text {
        color: #aaa;

        &:before {
          content: "\f058";
          color: #ccc; } } }

    &[type="radio"] {
      + .label-text {
        font-weight: normal; }

      + .label-text {
        &::before {
          -webkit-font-smoothing: antialiased;
          background-color: $color-white;
          border-radius: 50%;
          color: #999;
          content: '\f111';
          display: inline-block;
          font-family: 'Font Awesome 5 Free';
          font-size: 120%;
          font-style: normal;
          font-variant: normal;
          font-weight: normal;
          line-height: 1;
          margin-left: -1.4em;
          margin-right: .4em;
          speak: none;
          text-transform: none;
          width: 1em; } }

      &:checked {
        + .label-text {
          &::before {
            animation: effect 250ms ease-in;
            color: $accent;
            content: '\f192'; } } }

      &:disabled + .label-text {
        color: #aaa;

        &::before {
          //content: "\f111"
          color: #ccc; } } } }

  .toggle {
    input[type='radio'] {
      + .label-text {
        &::before {
          -webkit-font-smoothing: antialiased;
          color: #999;
          content: '\f204';
          display: inline-block;
          font-family: 'Font Awesome 5 Free';
          font-style: normal;
          font-variant: normal;
          font-weight: normal;
          line-height: 1;
          margin-right: 10px;
          speak: none;
          text-transform: none;
          width: 1em; } }

      &:checked {
        + .label-text:before {
          content: "\f205";
          color: #16a085;
          animation: effect 250ms ease-in; } }

      &:disabled {
        + .label-text {
          color: #aaa; }

        &:before {
          content: "\f204";
          color: #ccc; } } } }

  .yan_custom {
    input {
      &[type="checkbox"] {
        &:disabled + .label-text {
          color: #ff033e; }

        & + .label-text::before {
          color: #ff033e; }

        &:checked + .label-text:before {
          color: #ff033e; } } } } }


@keyframes effect {
  0% {
    transform: scale(0); }
  25% {
    transform: scale(1.3); }
  75% {
    transform: scale(1.4); }
  100% {
    transform: scale(1); } }

.form-horizontal .control-label {
  text-align: left !important;
  padding-top: 10px;
  font-weight: 500; }

// Стили для Чекбокса
.checkbox-custom {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: 50px;

  span {
    margin: 0 10px; }

  input[type="checkbox"] {
    margin: 0;
    position: relative;
    width: 80px;
    height: 20px;
    appearance: none !important;
    background: #dddddd;
    border-radius: 20px;
    @include mt(0.5s);
    box-shadow: inset 0 0 5px rgba(#000000, 0.1);
    outline: none !important;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      background: #ffffff;
      border-radius: 50%;
      top: 0;
      left: 0;
      @include mt(0.5s);
      transform: scale(1.3);
      box-shadow: 0 0 5px rgba(#000000, 0.1);
      border: 1px solid #dddddd;
      z-index: 1; } }

  input:checked[type="checkbox"] {
    background: $accent;
    outline: none !important;

    &::before {
      left: 60px; } } }

// Пагинатор
.razvitum-pagination {
  border: 0;
  border-radius: 0;

  & > li > a,
  & > li > span {
    border: 0;
    border-radius: 0;
    color: $accent; }

  & > .active {
    > a {
      background-color: $accent;
      border-color: $accent;

      &:focus,
      &:hover {
        background-color: $accent;
        border-color: $accent; }

      span {
        background-color: $accent;
        border-color: $accent;

        &:focus,
        &:hover {
          background-color: $accent;
          border-color: $accent; } } } } }

// Об обучении
.affix {
  position: sticky; }

.s-saying {
  background-color: $graybg;
  padding: 50px 0; }

.s-beruza {
  background-color: $active-color;
  padding: 50px 0; }

// Используемые цвета
.blue {
  color: $accent !important; }

.beruza {
  color: $active-color !important; }

.gray {
  color: $gray !important; }

.dark-gray {
  color: darken($gray, 15%) !important; }

.red {
  color: $danger !important; }

.green {
  color: $green !important; }

.orange {
  color: #fecf73 !important; }

.white {
  color: #ffffff !important; }

.bg-grey {
  background-color: #f2f2f2; }

.tooltip {
  opacity: 1 !important;

  &.right {
    .tooltip-inner {
      box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.3); }

    .tooltip-arrow {
      border-right-color: #FFF !important;

      &:after {
        content: '';
        position: relative;
        border-width: 5px 5px 5px 0;
        border-right-color: $accent !important;
        left: 2px;
        top: 50%;
        margin-top: -5px;
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        color: transparent; } } }

  &.top {
    .tooltip-inner {
      box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.3); }

    .tooltip-arrow {
      border-top-color: #FFF !important;

      &:after {
        content: '';
        position: relative;
        border-width: 5px 5px 0;
        border-top-color: $accent !important;
        left: 50%;
        bottom: 7px;
        margin-left: -5px;
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        color: transparent; } } }

  &-inner {
    padding: 10px;
    background: rgba($accent, 1);
    color: #ffffff;
    font-size: 14px;
    border-radius: 0;
    border: 1px solid #fff !important; } }

// Стили для Загрузки файлов input[type=file]
.file-wrap {
  width: 100%;
  position: relative;
  height: 56px;
  border-radius: 10em;
  border: 1px solid #bcbcbc;
  overflow: hidden;

  input[type="file"] {
    display: none;
    opacity: 0; }

  label {
    position: relative;
    width: 100%;
    height: 100%; }

  &_icon {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    font-size: 24px;
    color: $accent; }

  &_text {
    position: absolute;
    top: 50%;
    left: 60px;
    transform: translateY(-50%);
    font-weight: 500;
    color: #545454; }

  &_btn {
    position: absolute;
    top: 50%;
    right: 2px;
    transform: translateY(-50%); } }

// Шаги в списке слушателей и в корзине
.razvitum-steps {
  padding: 30px 0;
  font-size: 0.8em;

  ol {
    list-style: none;
    display: table;
    margin: 0;
    padding: 0;
    counter-reset: list 0;
    width: 100%;

    li {
      position: relative;
      display: table-cell;
      width: 1%;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 95%;
        height: 1px;
        display: block;
        background-color: #000; }

      &:last-child {
        &::before {
          display: none; } }

      a {
        color: #000000; }

      &.active {
        font-weight: bold;

        i.counter {
          &::before {
            color: #FFFFFF; }

          &::after {
            background-color: $orange; } } }

      &.done {
        i.counter {
          &::before {
            color: #FFFFFF; }

          &::after {
            background-color: $accent; } } }

      span {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        background-color: #ffffff;
        padding-right: 10px;
        vertical-align: middle;

        i.counter {
          border-radius: 50%;
          overflow: hidden;
          min-width: 46px;
          width: 46px;
          height: 46px;
          display: block;
          position: relative;
          font-style: normal;
          margin-right: 10px;

          &::before {
            counter-increment: list;
            content: counter(list);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
            font-size: 14px; }

          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 105%;
            height: 105%;
            display: block;
            z-index: 1;
            background-color: $graybg; } } } } } }

.badge {
  &.badge-danger {
    background-color: $danger !important;
    color: #ffffff !important; } }


.collapsed {
  .caret {
    transform: rotate(-90deg); } }

.input-range-wrap {
  input[type="range"] {
    appearance: none;
    width: 100%;
    height: 20px;
    padding: 0;
    border-radius: 50px;
    border: 1px solid $border;
    box-shadow: inset 0 0 5px rgba(#999999, 0.3);
    outline: none; }

  input[type="range"]::-webkit-slider-thumb {
    appearance: none;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: #ffffff;
    border: 1px solid $border;
    box-shadow: 0 0 10px rgba(#999999, 0.3);
    cursor: pointer; } }


.tutor-online {
  display: flex;
  align-items: center;
  float: right;
  margin-top: 25px;
  margin-left: 5px;
  position: absolute;
  right: 0;
  bottom: 10px;
  font-size: 13px;

  &-text {
    color: $accent;
    margin-right: 15px; }

  &-icon {
    display: flex;
    align-items: center;
    margin-right: 5px;
    transition: all 0.3s linear;

    a {
      display: flex;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
      vertical-align: middle;

      span.text {
        opacity: 1;
        display: block; }

      span.icon {
        background-color: $active-color;
        min-width: 30px;
        width: 30px;
        min-height: 30px;
        height: 30px;
        display: inline-block;
        border-radius: 50%;
        position: relative;
        margin-right: 8px;

        i {
          color: $accent;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 16px; } } }

    &.text-1 {
      span.text {
        animation-name: tutor-animation;
        animation-duration: 2s;
        animation-delay: 2s;
        animation-timing-function: ease;
        font-size: 0em; } }

    &.text-2 {
      span.text {
        animation-name: tutor-animation;
        animation-duration: 2s;
        animation-delay: 5s;
        animation-timing-function: ease;
        font-size: 0em; } }

    &.text-3 {
      span.text {
        animation-name: tutor-animation;
        animation-duration: 2s;
        animation-delay: 8s;
        animation-timing-function: ease;
        font-size: 0em; } } } }

@keyframes tutor-animation {
  0% {
    font-size: 0em;
    opacity: 0; }
  25% {
    font-size: 1em;
    opacity: 1; }
  50% {
    font-size: 1em;
    opacity: 1; }
  75% {
    font-size: 1em;
    opacity: 1; }
  100% {
    font-size: 0em;
    opacity: 0; } }


// Боковое меню для LMS
.lms-menu-wrap {
  position: relative;

  .lms-menu {
    margin-top: 20px;
    margin-bottom: 30px;
    width: 100%;
    overflow: hidden;
    transition: width 0.1s linear;

    #btn-menu-bars {
      cursor: pointer; }

    i {
      font-style: normal; }

    ul.fa-ul {
      margin: 0;

      li {
        position: relative;
        white-space: nowrap;
        padding: 1.2em 0;
        cursor: pointer;
        color: #777777;
        margin-left: 10px;

        a {
          color: #777777; }

        .fa-stack {
          position: absolute;
          left: 5px;
          top: 16px;
          overflow: hidden;
          z-index: 2; }

        .fa-stack-text {
          padding-left: 55px; } } }

    .collapse-wrap {
      background-color: #ACACAC;
      position: relative;
      margin-top: 0.5em;

      &::before {
        content: '';
        position: absolute;
        top: -10px;
        left: 17px;
        display: block;
        width: 16px;
        height: 16px;
        background-color: #f2f2f2;
        transform: rotate(45deg); }

      .collapse-text {
        p.collapse-text-head {
          color: #ffffff;
          position: relative;
          padding: 1.2em 1em 0.5em 4em; }

        ul {
          list-style: none;
          margin: 0;
          padding: 0;

          li {
            margin: 0;
            position: relative;
            padding: 0.2em 0.2em 0.2em 4em;

            a {
              color: #ffffff;
              display: block;
              padding: 0.5em 0;
              width: 100%;
              @include mt(0.2s);

              &:hover {
                text-decoration: underline;
                color: #000000; } } } }

        .mini-icon {
          position: absolute;
          left: 15px; } } } }

  .final-test {
    border: 2px Solid $accent;
    padding: 1em 1em 1em 0;
    border-radius: 50px;

    .fa-stack {
      color: $accent; } }

  .final-test.bd {
    border: none !important; }

  .lms-menu.w-100 {
    width: 55px; } }

.list-group-item {
  &:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

  &:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }

  &.active,
  &.active:focus,
  &.active:hover {
    background-color: $accent;
    border-color: $accent; }

  &.razvitum {
    &.active,
    &.active:focus,
    &.active:hover {
      color: inherit;
      background-color: inherit;
      border-left: 5px solid $accent;
      border-right: 1px solid #ddd;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd; }

    &:hover {
      color: $accent; } } }

.carousel.carousel-dark {
  .carousel-control {
    width: 4%;
    background-image: none; }

  .carousel-indicators {
    li {
      box-shadow: 0 0 6px 0 rgba(128, 128, 128, 0.5); } } }

.carousel.carousel-light {
  .carousel-control {
    width: 4%;
    background-image: none;
    color: $accent !important; }

  .carousel-indicators {
    li {
      border-color: $accent;
      box-shadow: 0 0 6px 0 rgba(128, 128, 128, 0.5);

      &.active {
        background-color: $accent; } } } }

.panel-speech {
  position: relative;
  border-color: $orange;
  background-color: $orange;
  border-radius: 15px;

  .panel-body {
    background-color: transparent; }

  &:before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: 30px;
    bottom: -20px;
    border: 10px solid;
    border-color: $orange transparent transparent $orange; } }

.select2-container {
  width: 100% !important;
  background: #f0f0f0 !important; }

a {
  color: $accent; }

//welcome-page для Платформы col-lg, col-md
.panel-speech-welcome {
  position: relative;
  border-color: $orange;
  background-color: $orange;
  border-radius: 15px;

  .panel-body {
    background-color: transparent; }

  &:before {
    content: " ";
    position: absolute;
    left: -30px;
    top: 20px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 20px 30px;
    border-color: transparent transparent #fecf73 transparent; } }
