@import 'vars';
@import 'fonts';
@import 'spritePNG';
@import 'mixins/calc';
@import 'node_modules/slick-carousel/slick/slick.scss';

.about {
  &-menu {
    margin: 8px 0 6px;

    &__item {
      color: $headgray;
      font-size: .875em;
      text-decoration: none;

      &:hover {
        color: $headgray; } } }

  &-carousel {
    padding: 2px 0;
    //border-top: 2px solid #fff
    background-color: #1523aa;

    &__btn {
      background: none !important;
      background-image: none !important; }

    &__arrow--back {
      width: $arrow-back-width;
      height: $arrow-back-height;
      // position: relative
      &:before {
        @include psblock(block, absolute);
        top: calc(50% - #{$arrow-back-height} / 2);
        left: -71px;
        @include sprite($arrow-back);
        @media(max-width: 1330px) {
          left: 10px; } } }

    &__arrow--fow {
      width: $arrow-fow-width;
      height: $arrow-fow-height;

      &:before {
        @include psblock(block, absolute);
        top: calc(50% - #{$arrow-fow-height} / 2);
        right: -71px;
        @include sprite($arrow-fow);
        @media(max-width: 1330px) {
          right: 10px; } } } }

  &__title {
    margin-top: 77px;
    margin-bottom: 38px;
    @include ff-roboto-light;
    font-size: 2.25em;
    color: $headgray;
    text-align: center; }

  &__subhead {
    margin-top: 84px;
    margin-bottom: 42px;
    @include ff-arial;
    font-size: 1.125em;
    color: $headgray;
    text-align: center; }

  &__text {
    @include ff-arial;
    text-align: center;
    color: $headgray;
    font-size: 1.125em;
    line-height: 1.65em;

    &--left {
      text-align: left; }

    &--docs {
      text-align: left;
      font-size: 1em; } }

  &__list {
    &-item {
      margin-bottom: 42px;
      padding-left: 70px;
      position: relative;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 5px;
        left: 15px;
        width: 40px;
        height: 40px;
        padding-top: 3px;
        border: 2px solid $accent;
        border-radius: 20px;
        text-align: center;
        color: $accent;
        font-size: 1.125em; }

      &--one {
        &:before {
          content: '1'; } }

      &--two {
        &:before {
          content: '2'; } }

      &--three {
        &:before {
          content: '3'; } } } }

  &-docs {
    &__list {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      background-color: rgba(255, 255, 255, 0); }

    &__item {
      display: block;
      position: relative;
      width: 165px;
      height: 230px;
      margin-bottom: 30px;
      border: 1px solid $orange;
      overflow: hidden;
      box-shadow: 4px 4px 40px 10px #e2e2e2;

      &:before {
        @include psblock(none, absolute);
        z-index: 12;
        width: 100%;
        height: 100%;
        background: linear-gradient(30deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.75)); }

      &:hover {
        border-color: $highlight;

        &:before {
          display: block; } }

      img {
        width: 100%;
        height: auto; } } }

  // СЛАЙДЕР С ДАТАМИ
  &-dates-slider {
    width: 100%;
    margin-top: 60px;
    margin-bottom: 140px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    &__wrap {
      display: flex;
      flex-direction: column;
      align-items: center; }

    &__item {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 325px;
      background-color: #fff;
      box-shadow: 0 4px 36px 4px rgba(226, 226, 226, 1);
      @media(max-width: 991.98px) {
        width: 100%;
        height: 300px; }
      @media(max-width: 768px) {
        width: 100%;
        height: 250px; }
      @media(max-width: 480px) {
        width: 100%;
        height: 250px; } }

    &__year {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 165px;
      height: 100%;
      background-color: $accent;
      text-align: center;
      font-size: 7.5em;
      color: #fff;
      writing-mode: vertical-rl;
      text-orientation: mixed;
      transform: rotate(180deg);
      @media(max-width: 991.98px) {
        width: 150px;
        font-size: 6.5em; }
      @media(max-width: 768px) {
        width: 105px;
        font-size: 5em; }
      @media(max-width: 480px) {
        width: 75px;
        font-size: 3.5em; } }

    &__text-wrap {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 30px 30px 30px 38px;
      overflow: hidden;
      text-overflow: ellipsis;
      background-image: url(/img/about/about-slide-bg.png);
      background-repeat: no-repeat;
      background-position: left center;
      @media(max-width: 991.98px) {
        padding: 25px 25px 25px 30px; }
      @media(max-width: 768px) {
        padding: 20px 20px 20px 25px; }
      @media(max-width: 480px) {
        padding: 15px 15px 15px 20px; } }

    &__title {
      width: 100%;
      margin-bottom: 0.75em;
      text-align: left;
      font-size: 1.875em;
      @include ff-arial();
      @media(max-width: 991.98px) {
        margin-bottom: 0.5em;
        font-size: 1.5em; }
      @media(max-width: 768px) {
        margin-bottom: 0.25em;
        font-size: 1.25em; }
      @media(max-width: 480px) {
        margin-bottom: 0;
        font-size: 1.25em; } }

    &__text {
      @include ff-arial();
      font-size: 1em; }

    &__dot-wrap {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 65px; }

    &__dot {
      font-size: 1em;
      color: $accent;
      @include ff-arial();
      cursor: pointer;

      &:hover {
        color: $highlight; }

      &::after {
        content: '•';
        margin-left: 24px;
        margin-right: 24px;
        color: $accent;

        &:hover {
          color: $accent; } }

      &:last-of-type {
        &::after {
          content: '';
          margin: 0; } } }

    &__btn {
      background: none !important;
      background-image: none !important; }

    &__arrow--back {
      width: $arrow-back-width;
      height: $arrow-back-height;
      // position: relative
      &:before {
        @include psblock(block, absolute);
        top: calc(50% - #{$arrow-back-height} / 2);
        left: -71px;
        @include sprite($arrow-back);
        @media(max-width: 1330px) {
          left: 10px; } } }

    &__arrow--fow {
      width: $arrow-fow-width;
      height: $arrow-fow-height;

      &:before {
        @include psblock(block, absolute);
        top: calc(50% - #{$arrow-fow-height} / 2);
        right: -71px;
        @include sprite($arrow-fow);
        @media(max-width: 1330px) {
          right: 10px; } } } }


  &-greet {
    width: 100%;
    padding: 85px 0 45px 0;
    background-color: #e8e8e8;

    &__img-wrap {
      max-width: 100%;

      img {
        width: 100%;
        height: auto; } }

    &__text {
      @include ff-arial;
      text-align: left;
      color: $headgray;
      font-size: 1.125em;
      line-height: 1.75em; }

    &__text--name {
      @include ff-arial-bold;
      font-size: 1em; }

    &__autograph {
      width: 50%;
      height: auto; } }

  &-innovations {
    &__item {
      margin-top: 100px;
      font-size: 1em;
      color: $textgray;
      text-align: center;

      img {
        width: auto;
        height: 94px;
        margin-bottom: 55px; } } }

  &-facts {
    &__wrap {
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: 0;
      min-height: 100px;
      border-bottom: 4px solid #fff;
      background-image: url('/img/factsbg.png'); } } }
