// ВСПОМОГАТЕЛЬНЫЕ МИКСИНЫ

// вычисляем положение верхней точки спрайта в блоке
// $sh - высота спрайта
// $fh - высота фрейма
@mixin topcalc($sh, $fh) {
  $top-point: calc((#{$fh} / 2) - (#{$sh} / 2));
  @if #{$top-point} > 0 {
    top: $top-point;
  } @else {
    top: 0;
  }
}

// аналогично для левой точки
// $sw - ширина спрайта
// $fw - ширина фрейма
@mixin leftcalc($sw, $fw) {
  $left-point: calc((#{$fw} / 2) - (#{$sw} / 2));
  @if #{$left-point} > 0 {
    left: $left-point;
  } @else {
    left: 0;
  }
}

// миксин псевдо-блока
// $display - св-во display
// $pos - cв-во position
@mixin psblock($display, $pos) {
  content: '';
  display: $display;
  position: $pos;
}

@mixin rubble() {
  content: '₽';
  display: inline-block;
  margin-left: 0.25em;
}

// миксин стилизации checkbox'а в форме с использованием символов @fontawesome
@mixin chbx-fa($col, $indent, $font: 'Font Awesome 5 Free') {
  display: none;

  & + label:before {
    font-family: $font;
    display: inline-block;
    content: '\f111';
    letter-spacing: $indent;
    color: $col;
  }

  &:checked + label:before {
    content: '\f058';
    animation: effect .25s ease-in;
    animation-duration: 0.25s;
    animation-timing-function: ease-in;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    animation-name: effect;
  }
}
