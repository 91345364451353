@import 'vars';

.faq-wrap {
  h2 {
    -webkit-text-stroke-width: .35px;
    color: $textgray;
    font-family: RobotoLight, sans-serif;
    font-size: 1.5rem; }

  textarea {
    &.form-control {
      padding: 20px; } }

  input {
    &[type='search'] {
      background-image: url('data:image/svg+xml,%3Csvg width="10.239" height="10.239" clip-rule="evenodd" fill-rule="evenodd" image-rendering="optimizeQuality" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" viewBox="0 0 2709 2709" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath fill="%23545454" d="M1026 44c549 0 995 445 995 995 0 223-73 429-197 595l856 856-175 175-852-852c-171 139-389 222-626 222-549 0-995-445-995-995 0-549 445-995 995-995zm0 296c386 0 699 313 699 699s-313 699-699 699-699-313-699-699 313-699 699-699z"/%3E%3C/svg%3E');
      background-position: right calc(.375em + .1875rem) center;
      background-repeat: no-repeat;
      background-size: calc(.75em + .375rem) calc(.75em + .375rem);
      padding-right: calc(1.5em + .75rem);

      &.waiting {
        background-color: $color-white;
        background-image: url('/img/spinner1.gif');
        background-position: right center;
        background-repeat: no-repeat;
        background-size: 25px 25px; } } }

  .faq-category {
    border-radius: 0;
    border-top: 1px solid $lgray;
    box-shadow: none;

    .panel-collapse {
      .panel-body {
        border-top: 0; } }

    > .panel-collapse {
      > .panel-body {
        padding-right: 0; } }

    > .panel-heading {
      border-radius: 0;
      padding: 0;

      a {
        border-left: 5px solid $active-color;
        display: block;
        font-weight: bold;
        padding: 15px 20px 15px 15px;
        position: relative;
        transition: background-color ease .5s;

        &:hover {
          background-color: transparent; }

        .caret {
          position: absolute;
          right: 4px;
          top: 50%; }

        &.collapsed {
          border-left: 0;
          font-weight: normal;
          padding: 15px 20px;

          &:hover {
            background-color: $active-color; } } } } }


  .faq-question {
    box-shadow: none;
    margin-bottom: 20px;
    margin-top: 0;

    > .panel-heading {
      border: 0;
      box-shadow: none;
      padding: 0;

      a {
        background-color: $graybg;
        color: $accent;
        display: block;
        line-height: 1.5em;
        padding: 10px 25px 10px 15px;
        position: relative;
        transition: background-color ease .5s;

        span {
          position: absolute;
          right: 10px;
          top: 10px; }

        &:active,
        &:focus {
          text-decoration: none; }

        &.collapsed {
          background-color: transparent;
          color: inherit;

          span {
            display: none; } } } }

    > .panel-collapse {
      > .panel-body {
        background-color: transparent;
        transition: background-color ease .5s, background ease .5s;

        p {
          margin-bottom: 15px; } }

      &.collapse {

        &.in {
          > .panel-body {
            background-color: $graybg; } } }

      &.collapsing {
        > .panel-body {
          background-color: $graybg; } } } }


  .social-block {
    border-radius: 0;
    padding: 15px;

    h2 {
      font-size: inherit;
      margin-bottom: 15px;
      margin-top: 0; }

    a {
      background-image: none;
      border: 2px solid $gray;
      border-radius: 50%;
      color: $gray;
      cursor: pointer;
      display: inline-block;
      font-size: 1.4em;
      font-weight: 400;
      height: 2em;
      line-height: 2em;
      margin-bottom: 5px;
      overflow: hidden;
      padding: 0;
      text-align: center;
      text-decoration: none;
      touch-action: manipulation;
      user-select: none;
      vertical-align: middle;
      white-space: nowrap;
      width: 2em;

      &:hover {
        border-color: $accent;
        color: $accent; } } }

  .contact-types {
    .form-check {
      label {
        font-family: Roboto, Arial, sans-serif; } }

    .panel-heading {
      background-color: $color-white;
      border-radius: 0;
      padding-left: 25px;
      padding-right: 25px;
      position: relative;

      .caret {
        position: absolute;
        right: 15px;
        top: 50%; } }

    .panel-body {
      border-radius: 0;
      border-top: 0 !important; }

    h2 {
      a {
        color: $accent;
        display: block;
        font-family: RobotoLight, sans-serif;
        font-size: 1.5rem;
        font-weight: normal;
        padding: 15px 0;
        text-decoration: none;

        &:not(.collapsed) {
          .fa-fw {
            color: $accent; }

          &:focus,
          &:hover {
            text-decoration: none; } }

        &.collapsed {
          color: $textgray;

          &:focus,
          &:hover {
            color: $color-black;

            .fa-fw {
              color: $color-black; } } }

        .fa-fw {
          color: $gray;
          text-align: left;
          width: 2.5rem; } } } }

  .text-links {
    a {
      font-family: Roboto, sans-serif;
      font-size: 1em;
      color: $textgray;

      &:hover {
        color: $accent;

        i {
          color: $accent; } }

      i {
        font-size: 1.5em;
        color: $lgray;
        margin-right: 10px; } } } }
