@import 'fonts';
@import 'vars';
@import 'spritePNG';
@import 'mixins/calc';
@import 'mixins/styles';

.article {
  &__img-wrap {
    width: 100%;

    img {
      width: 100%;
      height: auto; } }

  &__statistic {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-top: 20px; }

  &__tag {
    margin-right: 50px;
    display: inline-block;
    @include ff-arial-bold;
    color: $lightgray;
    font-size: 1em;

    &:hover, &:visited {
      color: $lightgray;
      cursor: pointer; } }

  &__title {
    margin-top: 28px;
    margin-bottom: 44px;
    @include ff-arial;
    font-size: 2.25em;
    @media(max-width: 767px) {
      font-size: 1.9em; }
    text-align: left; }

  &__text {
    @include text-basic;
    text-align: left;

    table {
      width: 100%;
      max-width: 100%;
      margin-bottom: 1em;
      border: 1px solid $lightgray;

      > thead, > tbody, > tfoot {
        > tr {
          > th, > td {
            padding: 5px;
            line-height: inherit;
            vertical-align: top;
            border: 1px solid $lightgray; } } }
      // Bottom align for column headings
      > thead > tr > th {
        vertical-align: bottom;
        border-bottom: 2px solid $lightgray; }

      > thead > tr {
        > th, > td {
          border-bottom-width: 2px; } }
      // Remove top border from thead by default
      > caption + thead, > colgroup + thead, > thead:first-child {
        > tr:first-child {
          > th, > td {
            border-top: 0; } } }
      // Account for multiple tbody instances
      > tbody + tbody {
        border-top: 2px solid $lightgray; }
      // Nesting
      table {
        background-color: $lightgray; } } } }


.article-interest {
  padding: 0;
  @media(min-width: 992px) {
    padding: 0 0 0 30px; }

  &__title {
    margin: 0 0 25px 0;
    padding: 0;
    @include ff-arial-bold;
    font-size: 1em;
    color: $active-color; }

  & > .article-prev {
    margin-bottom: 70px; } }

.share {
  &__declaration {
    margin: 70px 0 40px 0;
    padding: 40px;
    border: 2px dashed $accent;

    p {
      margin: 0;
      padding: 0;
      font-size: 1.125em;
      line-height: 1.33em;
      text-align: center;
      color: $headgray; } }

  &__send {
    margin-top: 70px;
    text-align: center;
    @include ff-arial;
    font-style: italic;
    font-size: 1.25em;
    color: $headgray;

    span {
      color: $accent; } }

  &__auth {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 1em;
    color: $lightgray; }

  &__btn {
    padding-left: 30px;
    padding-top: 9px;
    background-color: #fff;
    border: 1px solid $accent;
    color: $accent;
    font-size: 1em;

    i {
      top: 5px;
      position: relative;
      padding-right: 7px; }

    &:hover {
      background-color: $accent;
      color: #fff; } }

  &__form-wrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin: 25px 0; }

  &__email-input {
    margin: 0 25px;
    padding: 10px 25px;
    width: 360px;
    max-width: 360px; }

  &__social {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 75px;
    margin-bottom: 50px; } }

.check-list {
  .spacer {
    margin-top: 400px; }
  position: relative;
  margin-bottom: 50px;

  /*&:before
    @include psblock(block, absolute)
    @include sprite($sheet)
    top: 0
    left: 0*/



  h4 {
    margin: 0;
    padding: 0;
    @include ff-arial;
    font-style: italic;
    font-size: 1em;
    line-height: 1.5em;
    color: $headgray;
    text-align: left; }

  p {
    margin: 0;
    padding: 0;
    font-style: italic;
    font-size: 1em;
    line-height: 1.5em;
    color: $headgray;
    text-align: left; }

  &__controls {
    color: #999;
    font-size: 1em; } }
