// Кнопки

.btn-login {
  height: auto;
  border-radius: 100px;
  text-align: center;
  border: 2px solid $accent;
  display: block;
  line-height: 50px;
  padding: 0;
  color: #5a5a5a;
  font-size: 0.9em;
  transition: all 0.5s;

  &:hover {
    color: #fff;
    background: #1422aa; } }

.btn-default {
  border-radius: 100px;
  border: 1px solid $border;
  color: #545454;
  padding: 1em 2em;
  background: #f6f6f6 !important;
  @include mt(0.25s);

  &:hover {
    background: #CCCCCC !important; } }

.btn-primary {
  border-radius: 100px;
  border: none;
  color: #fff;
  padding: 1em 2em;
  background: $accent;
  @include mt(0.25s);

  &:hover {
    background: $light-blue; } }

.btn-danger {
  border-radius: 100px;
  border: none;
  color: #fff;
  padding: 1em 2em;
  background: $danger;
  @include mt(0.25s);

  &:hover {
    background: $danger-light; } }

.btn-danger-outline {
  border-radius: 100px;
  border: 1px solid $danger !important;
  color: $danger;
  background: #ffffff;

  &:hover {
    color: #fff;
    background: $danger; } }

.btn-success {
  border-radius: 100px;
  border: none;
  color: #fff;
  padding: 1em 2em;
  background: $green;
  @include mt(0.25s);

  &:hover {
    background: $light-green; }

  .btn-sm {
    padding: 5px 10px; } }

.btn-primary-outline {
  border-radius: 100px;
  border: 1px solid $accent !important;
  color: $accent;
  background: #ffffff;

  &:hover {
    color: #fff;
    background: $accent; } }

.btn-link {
  color: $accent !important;

  &:hover {
    text-decoration: underline; } }

.btn-warning {
  padding: 0.8em 2em;
  background-color: $orange;
  border-radius: 10em;
  color: #000000;
  font-size: 1em;
  border: none;
  @media(max-width: 1200px) {
    font-size: 18px; }
  @media(max-width: 400px) {
    font-size: 14px; } }


.btn-white {
  border: 2px solid #ffffff;
  border-radius: 50px;
  padding: 0.8em 2em;
  color: #ffffff;
  background-color: transparent;

  font-weight: 700;
  font-size: 1em;
  @include mt(0.3s);

  &:hover {
    background-color: #ffffff;
    color: $accent !important;
    font-weight: 700; }

  &:focus {
    color: #ffffff; } }

.btn-white-min {
  border: 1px solid #ffffff;
  font-size: 1em;
  padding: 0.5em 2em !important;
  font-weight: 500;

  &:hover {
    font-weight: 500; } }

.btn-transp {
  border-radius: 50px;
  padding: 0.8em 2em;
  color: $accent;
  background: rgba(#fff, 0.7);
  font-weight: 700;
  font-size: 0.8em;
  text-align: center;
  @include mt(0.3s);

  &:hover {
    text-decoration: none;
    background: rgba(#fff, 0.8);
    color: #000000; } }


a.btn-calc {
  background-color: $highlight; }

.button-wrap {
  .btn-warning {
    margin-top: 4.2em; } }

.btn-round {
  border-radius: 50% !important; }

.btn-soc-wrap {
  text-align: center;

  span {
    margin-right: 0.4em; } }

.btn-soc-enter {
  border-radius: 50%;
  padding: 0;
  width: 2em;
  height: 2em;
  line-height: 2em;
  font-size: 1.7em !important;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  overflow: hidden;

  &.ok {
    border: none;
    max-width: 54px;
    color: #ffffff;
    background-color: #ee8208;

    &:hover {
      color: #ffffff;
      background-color: #cd6d08; } }

  &.mailru {
    border: none;
    max-width: 54px;
    color: #FF9E00;
    background-color: #005ff9;

    &:hover {
      color: #FF9E00;
      background-color: #0054d5; } }

  &.vk {
    border: none;
    max-width: 54px;
    color: #ffffff;
    background-color: #5181b8;

    &:hover {
      color: #ffffff;
      background-color: #4a6e9f; } }

  &.yandex {
    border: none;
    max-width: 54px;
    color: red;
    background-color: #DDDDDD;

    &:hover {
      color: red;
      background-color: #b6b6b6; } }

  &.facebook {
    border: none;
    max-width: 54px;
    color: #ffffff;
    background-color: #4267b2;

    &:hover {
      color: #ffffff;
      background-color: #29487d; } }

  &.sberbank {
    max-width: 56px;
    color: #22A330;
    background-color: #22A330;
    border: none;

    svg g path#bg {
      fill: #22A330; }

    svg path#c {
      fill: #FFFFFF; }

    &:hover {
      background-color: #1f8527;

      svg g path#bg {
        fill: #1f8527; } } }

  &.viber {
    border: none;
    max-width: 54px;
    color: #ffffff;
    background-color: #574E92;

    &:hover {
      color: #ffffff;
      background-color: #41316a; } } }
