// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$applause-name: 'applause';
$applause-x: 142px;
$applause-y: 32px;
$applause-offset-x: -142px;
$applause-offset-y: -32px;
$applause-width: 26px;
$applause-height: 30px;
$applause-total-width: 208px;
$applause-total-height: 168px;
$applause-image: '../img/spritePNG.png';
$applause: (142px, 32px, -142px, -32px, 26px, 30px, 208px, 168px, '../img/spritePNG.png', 'applause',);
$arrow-back-name: 'arrow-back';
$arrow-back-x: 176px;
$arrow-back-y: 25px;
$arrow-back-offset-x: -176px;
$arrow-back-offset-y: -25px;
$arrow-back-width: 24px;
$arrow-back-height: 27px;
$arrow-back-total-width: 208px;
$arrow-back-total-height: 168px;
$arrow-back-image: '../img/spritePNG.png';
$arrow-back: (176px, 25px, -176px, -25px, 24px, 27px, 208px, 168px, '../img/spritePNG.png', 'arrow-back',);
$arrow-fow-name: 'arrow-fow';
$arrow-fow-x: 176px;
$arrow-fow-y: 56px;
$arrow-fow-offset-x: -176px;
$arrow-fow-offset-y: -56px;
$arrow-fow-width: 24px;
$arrow-fow-height: 27px;
$arrow-fow-total-width: 208px;
$arrow-fow-total-height: 168px;
$arrow-fow-image: '../img/spritePNG.png';
$arrow-fow: (176px, 56px, -176px, -56px, 24px, 27px, 208px, 168px, '../img/spritePNG.png', 'arrow-fow',);
$building-name: 'building';
$building-x: 176px;
$building-y: 87px;
$building-offset-x: -176px;
$building-offset-y: -87px;
$building-width: 18px;
$building-height: 28px;
$building-total-width: 208px;
$building-total-height: 168px;
$building-image: '../img/spritePNG.png';
$building: (176px, 87px, -176px, -87px, 18px, 28px, 208px, 168px, '../img/spritePNG.png', 'building',);
$calendar-name: 'calendar';
$calendar-x: 95px;
$calendar-y: 0px;
$calendar-offset-x: -95px;
$calendar-offset-y: 0px;
$calendar-width: 43px;
$calendar-height: 48px;
$calendar-total-width: 208px;
$calendar-total-height: 168px;
$calendar-image: '../img/spritePNG.png';
$calendar: (95px, 0px, -95px, 0px, 43px, 48px, 208px, 168px, '../img/spritePNG.png', 'calendar',);
$coin-name: 'coin';
$coin-x: 142px;
$coin-y: 0px;
$coin-offset-x: -142px;
$coin-offset-y: 0px;
$coin-width: 30px;
$coin-height: 28px;
$coin-total-width: 208px;
$coin-total-height: 168px;
$coin-image: '../img/spritePNG.png';
$coin: (142px, 0px, -142px, 0px, 30px, 28px, 208px, 168px, '../img/spritePNG.png', 'coin',);
$display-name: 'display';
$display-x: 95px;
$display-y: 52px;
$display-offset-x: -95px;
$display-offset-y: -52px;
$display-width: 36px;
$display-height: 32px;
$display-total-width: 208px;
$display-total-height: 168px;
$display-image: '../img/spritePNG.png';
$display: (95px, 52px, -95px, -52px, 36px, 32px, 208px, 168px, '../img/spritePNG.png', 'display',);
$key-name: 'key';
$key-x: 176px;
$key-y: 119px;
$key-offset-x: -176px;
$key-offset-y: -119px;
$key-width: 16px;
$key-height: 18px;
$key-total-width: 208px;
$key-total-height: 168px;
$key-image: '../img/spritePNG.png';
$key: (176px, 119px, -176px, -119px, 16px, 18px, 208px, 168px, '../img/spritePNG.png', 'key',);
$licence-name: 'licence';
$licence-x: 142px;
$licence-y: 66px;
$licence-offset-x: -142px;
$licence-offset-y: -66px;
$licence-width: 24px;
$licence-height: 32px;
$licence-total-width: 208px;
$licence-total-height: 168px;
$licence-image: '../img/spritePNG.png';
$licence: (142px, 66px, -142px, -66px, 24px, 32px, 208px, 168px, '../img/spritePNG.png', 'licence',);
$medal-name: 'medal';
$medal-x: 142px;
$medal-y: 102px;
$medal-offset-x: -142px;
$medal-offset-y: -102px;
$medal-width: 24px;
$medal-height: 32px;
$medal-total-width: 208px;
$medal-total-height: 168px;
$medal-image: '../img/spritePNG.png';
$medal: (142px, 102px, -142px, -102px, 24px, 32px, 208px, 168px, '../img/spritePNG.png', 'medal',);
$money-name: 'money';
$money-x: 0px;
$money-y: 0px;
$money-offset-x: 0px;
$money-offset-y: 0px;
$money-width: 91px;
$money-height: 168px;
$money-total-width: 208px;
$money-total-height: 168px;
$money-image: '../img/spritePNG.png';
$money: (0px, 0px, 0px, 0px, 91px, 168px, 208px, 168px, '../img/spritePNG.png', 'money',);
$people-name: 'people';
$people-x: 176px;
$people-y: 0px;
$people-offset-x: -176px;
$people-offset-y: 0px;
$people-width: 32px;
$people-height: 21px;
$people-total-width: 208px;
$people-total-height: 168px;
$people-image: '../img/spritePNG.png';
$people: (176px, 0px, -176px, 0px, 32px, 21px, 208px, 168px, '../img/spritePNG.png', 'people',);
$percent-name: 'percent';
$percent-x: 95px;
$percent-y: 128px;
$percent-offset-x: -95px;
$percent-offset-y: -128px;
$percent-width: 29px;
$percent-height: 29px;
$percent-total-width: 208px;
$percent-total-height: 168px;
$percent-image: '../img/spritePNG.png';
$percent: (95px, 128px, -95px, -128px, 29px, 29px, 208px, 168px, '../img/spritePNG.png', 'percent',);
$sheet-name: 'sheet';
$sheet-x: 95px;
$sheet-y: 88px;
$sheet-offset-x: -95px;
$sheet-offset-y: -88px;
$sheet-width: 28px;
$sheet-height: 36px;
$sheet-total-width: 208px;
$sheet-total-height: 168px;
$sheet-image: '../img/spritePNG.png';
$sheet: (95px, 88px, -95px, -88px, 28px, 36px, 208px, 168px, '../img/spritePNG.png', 'sheet',);
$spritesheet-width: 208px;
$spritesheet-height: 168px;
$spritesheet-image: '../img/spritePNG.png';
$spritesheet-sprites: ($applause, $arrow-back, $arrow-fow, $building, $calendar, $coin, $display, $key, $licence, $medal, $money, $people, $percent, $sheet,);
$spritesheet: (208px, 168px, '../img/spritePNG.png', $spritesheet-sprites,);

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
